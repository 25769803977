import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {url} from '../utile/globarVariable';
import nprogress from "nprogress";
import 'nprogress/nprogress.css';
const AddSpecialization = () => {
  const [cName,setcName]=useState([]);

  const getCourseType=async(e)=>
  {
   
    console.log(formData.university);
   

     const d=await axios.post(`${url}/get-Course-Name`,
     {
      university:formData.university
     },
     
     );


setcName(d.data);

  


    




  }


   const navigator=useNavigate();

  const [UniversityName,setUniversityName]=useState([]);

  const getUniversityName=async(e)=>
  {
    const r=await axios.get(`${url}/get-University1`);
    setUniversityName(r.data);
  }
  const [formData,setFormData]=useState(
    {
      university:'',
      coursetype:'',
      specialization:'',
      specializationcode:'',
      coursefee:'',
      registrationfee:'',
      examfee:''
    }
  );
  useEffect(()=>
  {
    getUniversityName();

  },[cName])
  const handleInputValue=(e)=>
  {
    const {name,value}=e.target;

    setFormData(
      {
        ...formData,[name]:value
      }
    );
  }
  const HandleSubmit=async(e)=>
  {
    e.preventDefault();

    console.log(formData);
    try
    {
nprogress.start();
const r= await axios.post(`${url}/Add-Specialization`,
  formData
 );


  if(r.data.Status==true)
  {
   alert("Specialization Successfully Added");
   navigator("/Manage-Specialization");

  }
  else
  {
   alert("Specialization does not Added");

  }
    }
    catch(err)
    {

    }
    finally
    {
nprogress.done();

    }

  }
  
    return <>
    
    <main id="main" className="main">

    <div className="pagetitle">
      <h1>Add Specialization</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a>Manage Specialization</a></li>
          <li className="breadcrumb-item active">Specialization</li>
        </ol>
      </nav>
    </div>

    <section className="section dashboard">
        <form onSubmit={HandleSubmit}>
      <div className="row">

      <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-12 col-xms-12">
        <label for="inputEmail3" className="col-form-label">Select University</label>
        <select name="university" onBlur={getCourseType} onChange={handleInputValue} value={formData.university} className="form-control" id="">
           
            <option value="" disabled>Select University</option>

{
  UniversityName.map((en)=>(
    <option value={en.id}>{en.name}</option>
  ))
}
          
          </select>
    </div>
      <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-0 col-xsm-0"></div>
     
     
      <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-12 col-xms-12">

<label for="inputEmail3" className="col-form-label">Course</label>

<select name="coursetype" onChange={handleInputValue} value={formData.coursetype} className="form-control" id="">
<option value="" disabled>Select Course</option>
{

cName.length>0 ? 
(
cName.map((en)=>(
<option value={en.id} >{en.coursename}</option>

))

):''
}

</select>
</div>


     
     
     

      
        
      </div>

      <div className="row">

      <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-12 col-xms-12">

<label for="inputEmail3" className="col-form-label">Specialization</label>
<input type="text" name='specialization' onChange={handleInputValue} value={formData.specialization} className="form-control" id="inputEmail"/>

</div>


      
        <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-0 col-xsm-0"></div>
        <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-12 col-xms-12">
          <label for="inputEmail3" className="col-form-label">Specialization Code</label>
          <input type="text" name='specializationcode' onChange={handleInputValue} value={formData.specializationcode} className="form-control" id="inputEmail"/>
        </div>
    
      
  
        
          
    </div>


    <div className="row">
    <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-12 col-xms-12">
  
  <label for="inputEmail3" className="col-form-label">Course Fee</label>
  <input type="text" name='coursefee' onChange={handleInputValue} value={formData.coursefee} className="form-control" id="inputEmail"/>

</div>
       
        <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-0 col-xsm-0"></div>
       
       
        <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-12 col-xms-12">
          <label for="inputEmail3" className="col-form-label">Registration Fee</label>
          <input type="text" name='registrationfee' onChange={handleInputValue} value={formData.registrationfee} className="form-control" id="inputEmail"/>
        </div>

        
  
        
          
    </div>


    <div className="row">
    <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-12 col-xms-12">
  
  <label for="inputEmail3" className="col-form-label">Exam Fee</label>
  <input type="text" name='examfee' onChange={handleInputValue} value={formData.examfee} className="form-control" id="inputEmail"/>

</div>



  
</div>
    


<div className="row my-5">
    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xms-10">
       
      </div>

      <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xms-2">

        <button className="btn btn-primary" type="submit">Add</button>
      </div>
</div>

    </form>
    </section>

  </main>
    
    </>
}



export default AddSpecialization;

